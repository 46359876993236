.cab-location-body{
    padding:15px 0;
    .aircab-order{
        margin-top:20px;
    }
    .cab-status-warp{
        color:#fff;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        text-transform: uppercase;
        .card-title{
            font-size:15px;
            font-weight: normal;
        }
    }
    .cab-board-green{
        background:linear-gradient(to right, #0bdea2, #74d20edb) !important;
        border:none;
    }
    .cab-board-orange{
        background:linear-gradient(to right, #ffbf96, #fe7096) !important;
        border:none;
    }
    .cab-board-red{
        background: linear-gradient(to right, #FD6A6A, #F93831);
        border:none;
    }
    .cab-board-blue{
        background:linear-gradient(to right,  #49a5f1, #1e7eff 99%) !important;
        border:none;
    }
    .map-wrap{
        margin-top:20px !important;
    }
}
