// .row{
//     margin: 0;
// }
.form-group{
    margin-bottom: 0 !important;
    input {
        border-radius: 0;
        font-size: 16px;
    }
    label {
        font-size: 16px;
        font-weight: 100;
    }
    label.radio-button:last-child {
        margin-left: 15px;
    }
}
.error-span{
    color: red;
    margin : 0 10px;
}

.hover-button .hover-button--on,
.hover-button:hover .hover-button--off {
    display: none;
}

.hover-button:hover .hover-button--on {
    display: inline;
}