.hcm-page {
    .hcm-filter-section {
        min-height: 20px;
        padding: 15px;
        font-size: 14px;
        margin-bottom: 20px;
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    }
    .show-entries {
        margin-top: 5px;
    }
}

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
}

.button-action {
  padding-top: 25px;
  button {
    margin-right: 5px;
  }
}

.attendance-detail {
    font-size: 16px;
    .row {
        border-bottom: 1px solid #c5bfbf;
        padding-bottom: 15px;
    }

    .row div {
        padding-top: 10px;
    }
  
    .row div:first-child {
        font-weight: bolder;
    }
    .ant-btn-success, .ant-btn-success:hover, .ant-btn-success:focus {
        color: #fff;
        background-color: #70C040;
    }
    .action-btn button.ant-btn {
        font-size: .8rem;
        margin: 5px;
    }
    span.label {
        font-weight: bold;
    }
}
