.table-responsive {
  overflow-x: unset;
}
.card-header .h4-color {
    color: white;
    font-size: 22px;
}

.row {
  margin: 0;
}
.card {
    ul.list-group {
        li {
            display: flex;
            span {
                flex: 1;
            }
        }
  }
  box-shadow: 5px 5px 10px #6c6c6c;
}
.form {
  h4 {
    margin-bottom: 30px;
  }
}
.error-span { color: red; }
.cap-amount-form {
    input { margin-bottom: 20px; }
    select { border-radius: 0; }
}

.btn {
  box-shadow: 3px 3px 3px #0a4695;
}

.submit {
  margin-right: 10px;
}

input[type='date']::before {
    content: attr(data-date);
}

.react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100%;
}

.modal-title {
    font-size: 17px;
}