.option{
    width: 26px;
}
.dropdown{
    position: relative;
    .opt-btn{
        background: transparent;
        border: 0;
        z-index: 1;
    }
    .opt-btn:focus{
        border: 0;
        outline: none;
    }
    .opt-list{
        position: absolute;
        top: 35px;
        // left: -56px;
        right: 0;
        list-style: none;
        text-align: left;
        background: #fff;
        border: 1px solid #ddd;
        padding: 0px;
        margin: 0;
        z-index: 1;
        border-radius: 4px;
        width: 160px;
        &::before{
            background: url('../../assests/images/up-arrow.svg');
            // background-color: #000;
            // background-position: left;
            content: " ";
            position: absolute;
            width: 16px;
            height: 16px;
            background-size: 16px;
            top: -13px;
            // left: 66px;
            right: 45px;
        }
        li{
            padding:  8px;
            &:hover{
                background: #bfd3f3;
            }
            img{
                width: 20px;
            }
            span{
                padding-left: 4px;
            }
        }
        
    }
}