.login-wrapper{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index: 33;
    background-image:linear-gradient(to bottom, #080c92 0%, #003f90 52%, #0055c4 100%);
    .form{
        width: 50%;
        margin: 0 auto;
        margin-top: 100px;
        border: 1px solid #085ac9;
        border-radius: 10px;
        padding:20px 50px 50px;
        background:#0046a559;
        text-align: center;
        box-shadow: -1px 1px 25px #03234c78;
        .form-group{
            text-align: left;
           
            label{
                color:#aec7ff;
                font-weight: 500;
                padding-left:15px;
            }
            input{
                background:#003679;
                border:0;
                border-radius: 25px;
                padding: 20px 15px;
                font-size:15px;
                color:#7ba4ff;
            }
            &::placehoder{
                color:#fff;
            }
        }
        h2{
            color:#fff;
            text-transform: uppercase;
            padding:20px 0 40px;
        }
        button{
            border:0;
            border-radius: 25px;
            padding:7px 50px;
            background:#faa534;
            color:#fff;
            margin-top:30px;
            &:hover{
                background:#de7f00;
            }
        }
        
    }
   
    p{
        position: absolute;
        text-align:center;
        width:100%;
        color:#7ba4ff;
        bottom:10px;
    }
}