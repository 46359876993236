.row {
  margin: 0;
}
.error-span {
  color: red;
  margin: 0 10px;
}

li.list-group-item {
  display: flex;
  span {
    flex: 1;
  }
}

.file-display-container {
  width: 100%;
}

.h4-color {
  color: white;
  font-size: 22px;
}
.file-status-bar {
  width: 75%;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  line-height: 50px;
  height: 50px;
}

.file-type {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  left: 30%;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}

.file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  left: 30%;
  color: #4aa1f3;
  position: absolute;
}

.file-error {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #9aa9bb;
}

.file-error-message {
  color: red;
}

.file-type-logo {
  width: 50px;
  height: 40px;
  top: 10%;
  left: 30%;
  background: url(../../assests/images/generic.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
}

.file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
  left: 0%;
  position: absolute;
}

.file-remove {
  position: absolute;
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  color: red;
  margin-right: -10px;
}

.customer-filter-section {
  min-height: 20px;
  padding: 15px;
  font-size: 14px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.button-action {
  padding-top: 25px;
}

.button-action button {
  margin-right: 5px;
}

.header-bar {
  background-color: #1077ff;
  padding: 16px;
}

.exprot-btn {
  color: #fff !important;
}

.form {
    select {
        border-radius: 0;
        font-size: 16px;
        padding-left: 10;
    }
    .ant-select-selector {
        height: 45px !important;
        border-radius: 5px !important;
        font-size: 18px;
    }
    .ant-select-selector {
        border-radius: 0 !important;
        .ant-select-selection-placeholder {
            color: #6d7277;
            padding: 6px 0 0 3px !important;
            font-size: 16px;
        }
        .ant-select-selection-search #companyId {
            padding-top: 10px;
            padding-left: 5px;
            border-radius: 0;
        }
    }
}