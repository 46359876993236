.caption-layout{
    display: flex;
    justify-content: space-between;
    width: 100%;

    .show-entries{
        display: flex;
        align-items: center;
        span{
            padding: 4px 6px;
        }
    }
    .search-entries{
         width: 30%;
        
        .search{
            width: 100%;
            border-radius: 5px;
            border: 1px solid #e9e9e9;
            margin: 5px;
            background: url(../../assests/images/search.svg) no-repeat  right;
            background-size: 15px;
            background-position: 97%;
            font-size:13px;

            padding: 8px 10px;
            box-shadow: 1px 1px 3px #e3e3e3;
        }
        
    }
    .show-entries{
        padding:5px;
        border: 1px solid #e9e9e9;
        box-shadow: inset 1px 1px 3px #e3e3e3;
        height: 35px;
        border-radius: 5px;
        font-size: 13px;

        
    }
    
}