

.drop-container {

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    width: 100%;
    height: 200px;
    border: 4px dashed lightgrey;
    border-collapse: separate;

}
.file-input {
    display: none;
}

.button-info {
    text-align: center;
    color: black ;

    font-size: 13px;
}

.upload-icon {
    width: 50px;
    height: 50px;
    background: url(../../assests/images/upload.png) no-repeat center center;
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 30px;
}

.drop-message {
    text-align: center;
    color: #b3b3b3;
    font-family: Arial;
    font-size: 20px;
}

.file-name {
    display: inline-block;
    vertical-align:top;
    margin-left: 50px;
    color: #4aa1f3;
}

.file-error {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: #9aa9bb;
}

.file-error-message {
    color: red;
}

.file-type-logo {
    width: 50px;
    height: 50px;
    background: url(../../assests/images/generic.png) no-repeat center center;
    background-size: 100%;
    position: absolute;
}

.file-size {
    display:inline-block;
    vertical-align:top;
    color:#30693D;
    margin-left:10px;
    margin-right:5px;
    margin-left: 10px;
    color: #444242;
    font-weight: 700;
    font-size: 14px;
}

.file-remove  {
    position: absolute;
    top: 20px;
    right: 10px;
    line-height: 15px;
    cursor: pointer;
    color: red;
    margin-right: -10px;
}