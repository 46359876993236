@font-face {
    font-family: 'Poppins';
    src: url("../../assests/fonts/poppins.ttf") format("ttf")         
  }

html, body, #root {
    height: 100%;
}

body{
    margin: 0;
    font-family: 'Poppins', Roboto,sans-serif !important;
    font-size: 1rem;
}

.main-content{
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    /* overflow-x: hidden; */
    background:#f2f2f2;
}
button.close:focus {
    outline: 0 !important;
}
table{
    table-layout: auto;
}

.content > .row {
    flex-grow: 1;
    height: 100%;

    .col-12 {
        height: 100%;
    }
}

.content {
    height: 100%;
    flex-grow: 1;
    padding-bottom: 5px;
    overflow-x: hidden;

    .btn-success:hover {
        color: #fff;
        background-color: grey;
        border-color: grey;
    }
}

.card{
    box-shadow: 5px 5px 10px #6c6c6c;
    height: 100%;
    ul{
        li{
            // display: flex;
            span{
                flex: 1;
            }
        }
    }

    .page-header {
        height: 69px;
    }

    .card-header{
        padding: .5rem 1.25rem;
        background-color: #0a56bb !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4{
            font-size:18px;
        }
    
        color: #fff;
        h4{
            margin: 0;
            font-size:18px;
            color: white;
        }
        .ant-btn, .ant-btn:focus, .ant-btn:hover {
            background-color: transparent !important;
            border: 2px solid #fff;
            color: #fff;
            border-radius: 2rem;
            height: 45px !important;
        }
    }

    .card-body {
        padding: 1rem;
        display: flex;
        flex-direction: column;

        .button-action button {
            font-size: .8rem;
        }
    }

    .card-footer {
        padding: .5rem 1.25rem;

        ul.pagination {
            margin-top: .5rem;
            margin-bottom: .5rem;
            font-size: .75rem;
        }
    }
}

tr{
    color: #566787;
}
.label{
    font-weight: bold;
}

.ml-2{
    margin-left :2px
}

.row{
    margin: 0;
}

.form-border{
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f2f252;
    border: 1px solid #e3e3e3;
    // border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

.table-responsive{
    overflow-x: unset
}

.form{
    h4{
        margin-bottom: 30px;
    }
}

.error-span{
    color: red;
    font-size: 14px;
}

.btn {
    box-shadow: 3px 3px 3px #0a4695;
}

.button-action {
    .ant-btn-success, .ant-btn-success:hover, .ant-btn-success:focus {
        color: #fff;
        background-color: #70C040;
        border-color: #70C040;
    }
    .ant-btn-warning, .ant-btn-warning:hover, .ant-btn-warning:focus {
        color: #fff;
        background-color: #ffcc00;
        border-color: #ffcc00;
    }
}
  
/* Page Transition - ZM */
.transition-group {
    height: 100%;
    padding-left: 5px;
}
.fade-enter {
    opacity: 0.01;
}
  
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}
  
.fade-leave {
    opacity: 1;
}
  
.fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}