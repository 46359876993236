.row{
    margin: 0;
}
.aircab-order{
    .search-entries{
        display:none;
    }
}
.result-layout{
    display: flex;
    justify-content: space-between;
    width: 100%;

    h6{
        font-size:13px;
        margin-top:15px;
        margin-bottom: 5px;
    }

}
h4{
    margin: 0;
}
.error-span{
    color: red;
    margin : 0 10px;
}
.action-button{
    margin-top : 30px;
    padding:5px;
}
.list-group-item{
    display: flex;
    span{
        flex: 1;
    }
}
.button-action{
    padding-top: 20px;
}
.filter-section{
    min-height: 20px;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    .btn-success{
      margin-right:5px;
    }
    .advacne-search{
        width:100%;
        // border-top:1px dashed #ccc;
        margin-top: 20px;
        a{
            margin:10px 15px;
            display:inline-block;
            color:#222;
            img{
                width:13px;
                
            }
        }
       
    }
    label {
        font-size: 14px;
    }
}
.filter-badges {
    background-color: white;
    color: green;
    padding: 10px;
    margin: 0px 4px;
    border: 1px solid green;
    span{
        padding: 5px;
        margin: auto;
        cursor: pointer;
        color: #435d7d;
        font-size: medium;
        font-weight: 300;
    }
}
.filter-clear {
    background-color: white;
    color: red;
    padding: 10px;
    margin: 0px 4px;
    border: 1px solid red;
    cursor: pointer;
}
.aircab-status-information{
    background: #f3f9ff;
    font-size: 13px;
    padding: 15px;
    border-radius: 15px;
    border: 1px dashed #0a56bb;
    margin: 20px 0;
    float:left;
    width:100%;
    ul{
      padding: 10px 15px;
      color: #6b6b6b;

      li{
        width:25%;
        padding:0 20px 0 0;
        float:left;
        h5{
          margin:0;
          padding:0;
          font-size: 15px;
        }
      }
    }
}
