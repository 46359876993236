.main-layouts{
    float:left;
    width: 85%;
    margin-left: 15%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.detail-list{
    margin-top: 30px;
}
.row{
    margin: 0;
}
.error-layouts{
    width: 100%;    
    position: absolute;
    padding-top: 30px;
    left: 0;
    right: 0;
}
.alert-layouts{
    width: 100%;    
    position: absolute;
    padding-top: 30px;
    left: 0;
    right: 0;
}
.success-alert, .error-alert{
    position: absolute;
    z-index: 1000;
    width: 50%;
    left: 25%;
    margin: auto;
    font-size: 16px;
}