.row{
    margin: 0;
}
.result-layout{
    display: flex;
    justify-content: space-between;
    width: 100%;
    h6{
        font-size:13px;
        margin-top:15px;
        margin-bottom: 5px;
    }
  .export-csv-btn{
    float: right !important;
    color: #fff;
    font-size: 15px;
  }
}    

h4{
    margin: 0;
}
.error-span{
    color: red;
    margin : 0 10px;
}
.action-button{
    margin-top : 30px;
    padding:5px;
}
.list-group-item{
    display: flex;
    span{
        flex: 1;
    }   
}
.button-action{
    padding-top: 20px;
}
.fuel-filter-section{
    min-height: 20px;
    padding: 15px;
    margin-bottom: 20px;
    font-size: 14px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}
.filter-badges {
    background-color: white;
    color: green;
    padding: 10px;
    margin: 0px 4px;
    border: 1px solid green;
    span{
        padding: 5px;
        margin: auto;
        cursor: pointer;
        color: #435d7d;
        font-size: medium;
        font-weight: 300;
    }
}
.filter-clear {
    background-color: white;
    color: red;
    padding: 10px;
    margin: 0px 4px;
    border: 1px solid red;
    cursor: pointer;
}

.ant-btn-warning, .ant-btn-warning:hover, .ant-btn-warning:focus {
    color: #fff;
    border-color: #ffcc00;
    background-color: #ffcc00;
    margin-right: 15px;
}
