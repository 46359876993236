.action-button {
	margin-top: 30px;
	padding: 5px;
}
a.list-group-item {
	display: flex;
	span {
		flex: 1;
	}
	.listing-table {
		margin-top: 10px;
		font-size: 15px;
	}
}

.error-span {
	color: red;
	margin: 0 10px;
}

.filter-badges {
	background-color: white;
	color: green;
	padding: 10px;
	margin: 0px 4px;
	border: 1px solid green;
	span {
		padding: 5px;
		margin: auto;
		cursor: pointer;
		color: #435d7d;
		font-size: medium;
		font-weight: 300;
	}
}

.new-fuel-partner {
	box-shadow: 3px 3px 3px #0a4695;
    background-color: transparent;
    border: 2px solid white;
    padding: 0.5rem 1rem;
    font-size: .8rem;
    border-radius: 2rem;
}
