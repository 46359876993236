.row{
    margin: 0;
}
.sidebar{
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 5;
    width:15%;
    margin-bottom: 5px;
    float:left;
    font-weight: 200;    
    background-position: center center;
    background-size: cover;      
    background: #fff !important;
    box-shadow: 5px 5px 10px #cdcdcd;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    .logo{
        display: flex;
        color: #fff;        
        align-items: center;
        justify-content: left;
        padding: 10px;
        img{
            width: 46px;
        }
        h4{
            font-size: 24px;
            padding: 4px 10px;
            color:#0a56bb;
        }
    }
    .nav{
        margin: 0;
        .nav-item{
            color: #f8f9fa;
            opacity: .86;
            border-bottom:5px solid #f2f2f2;
            border-left:0px;
            cursor: pointer;
            a{
                color: #666;
                display: flex;
                text-transform: uppercase;
                padding: .5rem 1rem;
                font-size: 15px;
                text-transform: capitalize;
             
                
            }
            a:hover{
                background: #f2f2f2;
                color: #000;
                // padding: 1rem 1rem;                
            }
            .active{
                background:#f2f2f2;
                border-left:3px solid #0a56bb;
            }
        }
    }

}