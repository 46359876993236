.row{
    margin: 0;
}
.result-layout{
    display: flex;
    justify-content: space-between;
    width: 100%;
    h6{
        font-size:13px;
        margin-top:15px;
        margin-bottom: 5px;
    }
    .export-csv-btn{
        float: right !important;
        margin : 5px;
        color: #fff;
        font-size: 15px;
      }
    .show-result{
        display: flex;
        align-items: center;
        span{
            padding: 4px 6px;
        }
    }
    .export-btn{
        .search{
            border-radius: 4px;
            border: 1px solid #ddd;
            padding: 4px;
        }
    }
}    

h4{
    margin: 0;
}
.error-span{
    color: red;
    margin : 0 10px;
}

.list-group-item{
    display: flex;
    span{
        flex: 1;
    }   
}
.agent-button-action{
    padding-top: 20px;
    .btn {
        font-size: 16px;
    }
}
.agent-filter-section{
    min-height: 20px;
    padding: 15px;
    margin-bottom: 20px;
    font-size: 14px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}
.filter-badges {
    background-color: white;
    color: green;
    padding: 10px;
    margin: 0px 4px;
    border: 1px solid green;
    span{
        padding: 5px;
        margin: auto;
        cursor: pointer;
        color: #435d7d;
        font-size: medium;
        font-weight: 300;
    }
}
.filter-clear {
    background-color: white;
    color: red;
    padding: 10px;
    margin: 0px 4px;
    border: 1px solid red;
    cursor: pointer;
}