.ant-pagination {
    float: right;
    padding: 10px
}
.show-details {
    display: inline;
    line-height: 50px;
    vertical-align: middle;
    font-size: 16px;
    div {
        display: inherit;
        .bold-text {
            font-weight: bold;
        }
    }
}