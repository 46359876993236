.row{
    margin: 0;
}
.error-span{
    color: red;
    margin : 0 10px;
}
.action-button{
    margin-top : 30px;
    padding:5px;
}
ul.list-group {
    li {
        display: flex;
        span{
            flex: 1;
        }
    }
}
.button-driver{
    box-shadow: 3px 3px 3px #0a4695;
    background-color: transparent;
    border: 2px solid white;
    padding: 0.5rem 1.5rem;
    font-size: .8rem;
    border-radius: 2rem;
}

.form {
    .ant-select-selector {
        height: 45px !important;
        border-radius: 5px !important;
        font-size: 18px;
    }
    .ant-select-selector {
        border-radius: 0 !important;
        .ant-select-selection-placeholder {
            color: #6d7277;
            padding: 6px 0 0 3px !important;
            font-size: 16px;
        }
        .ant-select-selection-search #companyId {
            padding-top: 10px;
            padding-left: 5px;
            border-radius: 0;
        }
    }
}
