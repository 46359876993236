th:first-child,
td:first-child {
  white-space: nowrap;
}

.order-page .order-filter-section {
    min-height: 20px;
    padding: 15px;
    margin-bottom: 20px;
    font-size: 14px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.order-opt .opt-list li:nth-child(2) {
  display: none;
}

.order-id {
  padding: 10px 0;
}

.order-id,
.order-status,
.order-fare {
  display: block;
  padding-bottom: 10px;
  text-align: center;
}

.btn-xs.finished {
    background-color: rgb(35, 150, 35);
    padding: 1px 5px;
    font-size: 12px;
    display: block;
}

.order .btn {
  box-shadow: none;
  margin-bottom: 5px;
  border-radius: 0;
  color: #fff;
}

.button-action {
  padding-top: 25px;
}

.button-action button {
  margin-right: 5px;
}

.export-btn {
    color: #fff !important;
}

.trip-detail div,
.trip-desc div,
.date-time div {
  padding-top: 5px;
}

.order-detail .row {
  font-size: 14px;
  border-bottom: 1px solid #c5bfbf;
  padding-bottom: 15px;
  margin: 0 !important;
}

.order-detail .row div {
  padding-top: 10px;
}

.order-detail .row div:first-child {
  font-weight: bolder;
}

a.order-link {
  text-decoration: underline;
  font-size: 15px;
}

input[type="checkbox"] {
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  padding: 10px;
}

.form-check-label {
  /* Checkbox text */
  font-size: 110%;
  display: inline;
}


div.form-check-inline {
  padding-right: 15px;
}

.order-page div.table-responsive {
  max-height: 500px;
}

.order-page .show-entries {
  margin-top: 5px;
}
