.row{
    margin: 0;
}
.profile-page {
    font-size: 16px;
    div.card-header {
        padding: 1rem 1.25rem;
    }
    div.row{
        padding: 20px;
        border-bottom: 1px solid #c6bdbd;
    }
    div.row:last-child {
        border-bottom: none;
    }
    .col-sm-3 span {
        font-weight: bolder;
    }
    button {
        margin-left: 20px;
    }
    .badge {
        line-height: 2;
    }
}
