
.table-responsive{
    max-width: 1300px;
    overflow-y: auto;
    overflow-x : auto;
    border-collapse: collapse;
    min-height:300px;
    flex: 1 1 40px;
}
@media (min-width: 1300px){
    .table-responsive{
        max-width: 100%;
        overflow-y: auto;
        overflow-x : auto;
        border-collapse: collapse;
        min-height:300px;
    } 
}
.table-responsive thead th { position: sticky; top: 0; }
thead th{
    vertical-align: middle !important;
    text-align: center;
}

th{
    background: #eee;
    height: 100px;
    z-index: 2;
}
.badge-padding{
    padding: 10px !important;
}
.no-data{
    margin : auto;
    font-size: 20px;
}
.listing-table {
    font-size: 12px;
    thead{
        tr{
            th{
                height:auto;
                padding:5px 8px;
            }
        }
    }
    tr{
        td{
            line-height: 13px;
            text-align: center;
            padding:5px 8px;
            border-right: 1px solid#dcdcdc;

            .download-btn {
                font-size: 25px;
                color: blue;
            }
            .active-icon, .inactive-icon {
                font-size: 25px;
            }
            .active-icon {
                color: #228b22;
            }
            .inactive-icon {
                color: #ff0000;
            }
        }
        td:last-child {
            border-right: none;
        }
        td.align-left {
            text-align: left !important;
        }
        td.align-left.no-warp {
            white-space: nowrap;
        }
    }
    tr:last-child {
        border-bottom: 1px solid #dcdcdc;
    }
}

.fixedHead {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}
