.map{
    position: relative !important;
    width:100%;
    height: 400px;
}
.gm-style{
    .gm-style-iw-c{
       box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.11);
       width:250px;
       button.gm-ui-hover-effect{
           top:-1px !important;
           right:-1px !important;
       }
       h4{
           font-size: 18px;
           padding-bottom: 0px;
       }
       p{
           padding-bottom: 10px;
           margin-bottom: 0px;
       }
       ul{
           padding:0;
           b{
               font-weight: bold;
           }
       }
    }
}